<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden px-3">
                        <div class="card-body">
                            <div class="col-12 py-3">
                                <form @submit.prevent="postCourse" class="row">
                                    <div class="col-12">
                                        <div class="row p-auto">
                                            <div class="col-xl-12">
                                                <div class="avatar-upload"  v-show="!edit">
                                                    <div class="text-center p-2">
                                                        <label for="avatar">
                                                            <img width="100%" :src="files.length ? files[0].url : '/images/image-upload.png'" />
                                                        </label>
                                                    </div>
                                                    <div class="text-center p-2">
                                                        <file-upload
                                                        extensions="jpg,jpeg,png"
                                                        accept="image/png,image/jpeg,image/jpg"
                                                        name="avatar"
                                                        class="btn btn-current"
                                                        :drop="!edit"
                                                        v-model="files"
                                                        @input-filter="inputFilter"
                                                        @input-file="inputFile"
                                                        ref="upload" required>
                                                        Upload Banner
                                                        </file-upload>
                                                    </div>
                                                </div>

                                                <div class="avatar-edit" v-show="files.length && edit">
                                                    <div class="avatar-edit-image" v-if="files.length">
                                                        <cropper
                                                            class="cropper"
                                                            ref="cropper"
                                                            :src="files[0].url"
                                                            :stencil-props="{
                                                                handlers: {},
                                                                movable: false,
                                                                scalable: false,
                                                                aspectRatio: 4 / 3,
                                                            }"
                                                            :resize-image="{
                                                                adjustStencil: false
                                                            }"
                                                            image-restriction="stencil"
                                                        ></cropper>
                                                    </div>
                                                    <div class="text-center p-4">
                                                        <button type="button" class="btn btn-default" @click.prevent="$refs.upload.clear">Cancel</button>
                                                        <button type="submit" class="btn btn-current" @click.prevent="editSave">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 form-group mb-3">
                                                <label for="">Title</label>
                                                <input type="text" v-model="form.title" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Title">
                                            </div>
                                            <div class="col-12 form-group mb-3">
                                                <label for="">Content</label>
                                                <CustomCkEditor :ck-placeholder="'Enter your Content'" @ck-on-editor-input="setContent($event)"></CustomCkEditor>
                                            </div>
                                            <div class="col-12 form-group text-right my-4">
                                                <router-link :to="{name: 'BlogAdmin'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                                <input type="Submit" class="btn btn-current" value="Submit">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'
import CustomCkEditor from '../../../components/helpers/CustomCkEditor.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import FileUpload from 'vue-upload-component'
export default {
    name: "Calendar",
    components: {
        CustomCkEditor,
        FileUpload,
        Cropper
    },
    data(){
        return {
            edit: false,
            form: {
                thumbnail: '',
                title: '',
                content: ''
            },
            files:[],
            img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
        }
    },
    methods: {
        async postCourse(){
            let data = new FormData();
            data.append('thumbnail', this.form.thumbnail);
            data.append('title', this.form.title);
            data.append('content', this.form.content);
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/blog/article`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                        toast: true,
                        title: 'Article',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                })
                this.$router.push({ name: 'BlogAdmin' })
            }).catch(err => {
                if (err.response.status == 422) {
                    this.$swal({
                        toast: true,
                        title: 'Arrticle',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async editSave() {
            this.edit = false
            let oldFile = this.files[0]
            const result = this.$refs.cropper.getResult();
            let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
            let arr = new Uint8Array(binStr.length)
            for (let i = 0; i < binStr.length; i++) {
                arr[i] = binStr.charCodeAt(i)
            }
            let file = new File([arr], oldFile.name, { type: oldFile.type })
            this.$refs.upload.update(oldFile.id, {
                file,
                type: file.type,
                size: file.size,
                active: true,
            })
            
            let data = new FormData();
            data.append('dir', 'courses/banner');
            data.append('attachment', this.files[0].file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.files[0].response = res.data
                this.form.thumbnail = res.data.path
            }).catch(err => {
                console.log(err.response)
            })
        },
        alert(message) {
            alert(message)
        },
        setContent(value) {
            this.form.content = value
        },
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                this.$nextTick(function () {
                this.edit = true
                })
            }
            if (!newFile && oldFile) {
                this.edit = false
            }
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(jpg|png|jpeg)$/i.test(newFile.name)) {
                this.alert('Your choice is not a picture')
                return prevent()
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                newFile.url = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.url = URL.createObjectURL(newFile.file)
                }
            }
        },
    }
}
</script>
<style scoped>
.cropper {
  height: 600px;
  background: #DDD;
}
</style>